import router from '@/router';
import { setDataList } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";

export default class TermListViewModel {
  constructor() {
    this.searchDataList = [
      {
        title:'카테고리',
        type: 'SelectboxInput',
        dataList:[],
        isFull:true,
        value1:null,
        value2:'',
      },
    ]
    this.boardData = {
      title:'FAQ 목록',
      drawDataList: [
        {
          title:'No.',
          width:'60px',
          value:'id',
          isSorting: true
        },
        {
          title:'카테고리',
          width:'120px',
          value:'category',
          isAlignLeft: true
        },
        {
          title:'Q&A',
          width:'',
          value:'question',
          valueCustom:[
            { 
              type: 'text',
              value: '<strong class="tit_question">'
            },
            {
              type: 'value',
              value:'question',
            },
            { 
              type: 'text',
              value: '</strong>'
            },
            {
              type: 'value',
              value:'answer',
            },
          ],
          // filter:{
          //   name:'convertLineFeed'
          // },
          class: {
            name: 'fold'
          },
          isAlignLeft: true
        },
        {
          title:'편집',
          width:'76px',
          value:'',
          button: { text:'편집', size:'small', style:'secondary_border', onClickEvent: (data) => this.onClickEdit(data) },
        },
      ],
      option:{
        isTotal: true,
        isSize: true
      }
    };
    this.dataList = [];
    this.foldedDataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }
  init(){
    this.getData();
  }
  onClickRow(rowId){
    const isFolded = this.foldedDataList.length > 0 && this.foldedDataList.find(item => item === rowId);
    if(isFolded){
      this.foldedDataList = this.foldedDataList.filter(item => item != rowId);
    }else{
      this.foldedDataList.push(rowId);
    }
  }
  onClickEdit(data){
    router.push({ name:'SERVICE_FAQ_MODIFY', params: { id : data.id } })
  }
  getData(){
    this.dataList = [
      { id:'1', category:'레몬트리카드', question:'간편결제 서비스에 레몬카드를 등록할 수 있나요?', answer:'네, 레몬카드를 간편결제 서비스에 등록하여 사용할 수 있습니다.\n- ISP페이북, SSG페이, 카카오페이, 네이버페이, 쿠페이, 미래에셋페이, 페이코, 배민페이, 토스페이에 등록하여 사용 가능합니다.\n- 만 14세 이하 청소년이라면 부모님의 동의를 받아 네이버 페이에 등록할 수 있습니다.\n\n※ 카드 신청 후 먼저 발급되는 모바일카드는 간편결제 앱에 등록하여 사용할 수 있습니다.\n※ 간편결제사 앱에서 이용 방법 및 사용처를 확인하여 사용해 주세요.' },
      { id:'2', category:'인증/보안', question:'비밀번호를 5회 틀리게 입력했어요. 어떻게 해야 하나요?', answer:'비밀번호 변경을 선택하시고 본인인증을 통해 비밀번호를 변경하여 로그인해주세요.' },
    ];
    this.paginationData.totalCount = 2;
    this.paginationData.totalPage = 1;
  }
}