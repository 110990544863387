<template>
  <PageWithLayout>
    <Search
      :searchDataList="viewModel.searchDataList"/>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      :foldedDataList.sync="viewModel.foldedDataList"
      @onClickRow="rowId => viewModel.onClickRow(rowId)"
      @onSearch="viewModel.onSearch()">
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="secondary"
          text="카테고리 관리"
          @onClickBtn="detailViewModel.onClickCategoryPoup()"/>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="FAQ 추가"
          routeName="SERVICE_FAQ_REGISTER">
          <IconSvg iconName="add" :size="12" iconColor="#fff" />
        </Button>
      </template>
    </Board>
    <template v-slot:popup>
      <FaqCagegoryPopup
        v-if="detailViewModel.isFaqCategoryPopup"
        :dataList="detailViewModel.model.categoryDataList"
        @onModifyOrder="orderData => detailViewModel.onModifyOrder(orderData)"
        @onClickAdd="detailViewModel.onClickAddCategory()"
        @onClickDelete="id => detailViewModel.onClickDeleteCategoryItem(id)"
        @onClickClose="detailViewModel.onClickCloseFaqCagegoryPopup()"
        @onClickComplete="detailViewModel.onClickCompleteFaqCagegoryPopup()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import FaqCagegoryPopup from '@/views/service/faq/view/popup/FaqCagegoryPopup';
import { setDataList,addDataListAll } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";
// viewModel
import FaqViewModel from '@/views/service/faq/viewModel/FaqViewModel'
import FaqDetailViewModel from '@/views/service/faq/viewModel/FaqDetailViewModel'

export default {
  name:'Faq',
  components:{
    PageWithLayout,
    Search,
    Board,
    Button,
    IconSvg,
    FaqCagegoryPopup
  },
  beforeMount(){
    this.viewModel.init();
    this.detailViewModel.init();
    this.viewModel.searchDataList[0].dataList = addDataListAll(setDataList(this.detailViewModel.model.categoryDataList, 'id', 'categoryName'),null,'전체 카테고리');
  },
  data(){
    return{
      viewModel: new FaqViewModel(),
      detailViewModel: new FaqDetailViewModel(),
      searchTypeDataList:[
        { id:'COURSE', text:'코스' },
        { id:'UNIT', text:'유닛' },
        { id:'QUIZ', text:'퀴즈' },
        { id:'TAG', text:'태그' }
      ],
      searchData:{
        searchType:'',
        searchValue:''
      }
    }
  },
}
</script>

<style scoped>
.board_comm::v-deep .fold .tit_question{display:block;padding-bottom:9px;line-height:20px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.board_comm::v-deep .fold .txt_tbl{display:block;overflow:hidden;height:29px;margin:9px 0 0;line-height:22px;}
.board_comm::v-deep .tr_fold .txt_tbl{height:auto;margin-bottom:9px;overflow:inherit;white-space:inherit;text-overflow:inherit}
.board_comm::v-deep .tr_fold .tit_question{overflow:visible;white-space:inherit;text-overflow:inherit;color:#222}
/* .board_comm::v-deep .icon_lemontree{margin-top:10px}
.board_comm::v-deep .tr_fold .icon_lemontree{transform:scaleY(-1)} */
@media (prefers-color-scheme: dark) {
  .board_comm::v-deep .tr_fold .tit_question{color:#fff}
}
/* 모바일 */
@media all and (max-width:1240px){
  .board_comm::v-deep .fold .txt_tbl{margin:0;padding:0}
}
</style>