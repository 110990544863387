import FaqModel from '@/views/service/faq/model/FaqModel';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class FaqDetailViewModel {
  constructor() {
    this.model = new FaqModel();
    this.isModify = false;
    this.isFaqCategoryPopup = false;
  }

  init(id){
    this.getFaqCategory();
    if(id){
      this.getFaqDetail(id)
    }
  }

  onClickCategoryPoup(){
    this.isFaqCategoryPopup = true;
  }
  onModifyOrder(orderData){
    console.log(orderData)
  }
  onClickAddCategory(){
    const newCategory = {
      id: this.model.categoryDataList.length,
      categoryName:'',
      editabled: true
    }
    this.model.categoryDataList.push(newCategory);
  }
  onClickDeleteCategoryItem(id){
    const requestDelete = () => {
      this.model.categoryDataList = this.model.categoryDataList.filter(item => item.id != id);
      store.dispatch('commonToast/fetchToastStart', `FAQ 카테고리가 삭제되었습니다.`);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>카테고리를 삭제할까요?</strong>해당 카테고리에 지정된 FAQ 게시물이 있는 경우,<br>기타 카테고리로 변경됩니다.`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  onClickCloseFaqCagegoryPopup(){
    this.isFaqCategoryPopup = false;
  }
  onClickCompleteFaqCagegoryPopup(){
    store.dispatch('commonToast/fetchToastStart', `FAQ 카테고리가 저장되었습니다.`);
    this.isFaqCategoryPopup = false;
  }
  
  onClickComplete(){
    let toastMessage = '';
    let routeName = '';
    if(this.isModify){
      toastMessage = '수정';
      routeName = 'APP_TERMS_DETAIL';
    }else{
      toastMessage = '등록';
      routeName = 'APP_TERMS_LIST';
    }
    store.dispatch('commonToast/fetchToastStart', `FAQ ${toastMessage}이 완료되었습니다.`);
    router.push({ name: routeName });
  }

  getFaqCategory(){
    this.model.categoryDataList = [
      { id:1, categoryName:'레몬트리카드', editabled: true },
      { id:2, categoryName:'인증/보안', editabled: true },
      { id:0, categoryName:'기타', editabled: false },
    ]
  }

  getFaqDetail(id){
    const resultData = { id:'1', categoryId:1, question:'간편결제 서비스에 레몬카드를 등록할 수 있나요?', answer:'네, 레몬카드를 간편결제 서비스에 등록하여 사용할 수 있습니다.\n- ISP페이북, SSG페이, 카카오페이, 네이버페이, 쿠페이, 미래에셋페이, 페이코, 배민페이, 토스페이에 등록하여 사용 가능합니다.\n- 만 14세 이하 청소년이라면 부모님의 동의를 받아 네이버 페이에 등록할 수 있습니다.\n\n※ 카드 신청 후 먼저 발급되는 모바일카드는 간편결제 앱에 등록하여 사용할 수 있습니다.\n※ 간편결제사 앱에서 이용 방법 및 사용처를 확인하여 사용해 주세요.' }
    this.model.setData(resultData);
  }
}