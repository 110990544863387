<template>
  <Popup
    title="FAQ 카테고리 관리"
    :maxWidth="500"
    closeBtnText="닫기"
    compeleteBtnText="저장"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete')">
    <GroupCategory
      :groupCategoryData.sync="categoryData"
      :dataList.sync="categoryData.dataList"
      :value.sync="categoryData.value"
      @onModifyOrder="orderData => $emit('onModifyOrder',orderData)">
      <template v-slot:item_category="slotProps">
        <span class="txt_item">
          <IconSvg iconName="drag" :size="18" iconColor="#bbb" />
        </span>
          <Input
            v-if="slotProps.itemData.editabled"
            :value.sync="slotProps.itemData.categoryName"/>
        <strong v-else class="tit_item fill_item">
          {{ slotProps.itemData.categoryName }}
        </strong>
        <span class="txt_item">
          <Button
            v-if="slotProps.itemData.editabled"
            btnSize="small"
            btnStyle="fourthly"
            text="삭제"
            @onClickBtn="$emit('onClickDelete',slotProps.itemData.id)"/>
          <template v-else>
            삭제불가
          </template>
        </span>
      </template>
    </GroupCategory>
    <p class="desc_info">💡 FAQ 카테고리 이름과 앱에서 노출되는 순서를 조정할 수 있습니다.</p>
    <template v-slot:footBtn>
      <Button
        btnSize="small"
        btnStyle="secondary"
        text="카테고리 추가"
        @onClickBtn="$emit('onClickAdd')"/>
    </template>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import GroupCategory from '@lemontree-ai/lemontree-admin-common-front/components/common/category/GroupCategory'
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';

export default {
  name:'FaqCagegoryPopup',
  props:{
    dataList: Array
  },
  components:{
    Popup,
    GroupCategory,
    IconSvg,
    Input,
    Button
  },
  mounted(){
    this.init();
  },
  data(){
    return{
      categoryData: {
        title : '카테고리',
        id : 'FaqCategory',
        index : 0,
        titleList: [
          { title:'순서' },
          { title:'카테고리 이름', isFill: true },
          { title:'삭제' },
        ],
        dataList: [],
        // contextMenu: [
        //   { icon:'✍️', text:'수정', event:(data) => this.onClickContextModify(data) },
        //   { icon:'🗑', text:'삭제', event:(data) => this.onClickContextDelete(data) },
        // ],
        isDragable:true,
        value:null
      },
    }
  },
  methods:{
    init(){
      this.categoryData.dataList = this.dataList
    }
  }
}
</script>
<style scoped>
.area_category::v-deep .list_category li{line-height:36px}
.area_category::v-deep .list_category li .tf_comm{display:block;flex:1 1}
.area_category::v-deep .list_category li .tit_item{padding:0 14px;font-size:16px;line-height:38px}
.area_category::v-deep .list_category li .txt_item{line-height:38px}
.area_category::v-deep .list_category li .icon_drag{margin:10px 0}

.area_category + .desc_info{margin-top:20px}
</style>