export default class FaqModel {
  constructor(){
    this.objRaw = {};
    this.categoryDataList = [];
    this.id = null;
    this.categoryId = null;
    this.question = '';
    this.answer = '';
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { id, categoryId, question, answer } = obj;
    this.id = id;
    this.categoryId = categoryId;
    this.question = question;
    this.answer = answer;
  }
  getData(){
    let obj = {
      id : this.id,
      categoryId : this.categoryId,
      question : this.question,
      answer : this.answer,
    }
    return obj;
  }
  getCategoryDataList(){
    return this.categoryDataList;
  }
}